<script>
import Horizontal from "./horizontal";
import ax from '../../helpers/restful/service';
import {
    teacherMainLayoutComputed,
} from "@/state/helpers";

export default {
    components: {
        Horizontal,
    },
    data() {
        return {
            uid: JSON.parse(localStorage.getItem('auth.user')).uid || 0,
            currentUser: null,
            loaded: false,
            loaderHeight: window.innerHeight * 0.1,
            videoPanelHeight: window.innerHeight * 0.2,
        };
    },
    validations: {

    },
    computed: {
        ...teacherMainLayoutComputed,
    },
    mounted() {
        document.body.classList.add('authentication-bg');
        this.getUserInfo();

        this.videoPanelHeight = window.innerWidth * 9 / 16;

    },
    methods: {
        async getUserInfo() {
            console.log("GetUserInfo");
            console.log("UID:", this.uid);

            const resp = await ax.post("/get-latest-user-info", { uid: this.uid });
            console.log("resp:", resp);

            if (resp.status != 200) {
                return;
            }

            this.currentUser = resp.data;
            console.log("currentUser:", this.currentUser);
            this.loaded = true;
            console.log("loaded:", this.loaded);
        },
        goToTest(){
            console.log("go to test");
            this.$router.push({ path: '/course-test' });
            return;
        },
    },
};
</script>

<template>
    <div>
        <Horizontal :layout="layoutType" v-if="loaded">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="mb-3 text-center">บทเรียนที่ 1 การวางแผนการเรียนการสอน
                                การเตรียมการสอนสำหรับครูแพทย์แผนไทย</h4>

                            <h5 class="mb-3 text-start">เนื้อหาการอบรม</h5>
                            <iframe width="100%" :height="videoPanelHeight" src="https://www.youtube.com/embed/BfGguu5cHvg"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen></iframe>
                            <hr class="my-3"/>
                            <iframe width="100%" :height="videoPanelHeight" src="https://www.youtube.com/embed/af_wuwxyEx8"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen></iframe>
                            <hr class="my-3"/>
                            <iframe width="100%" :height="videoPanelHeight" src="https://www.youtube.com/embed/BO64ub2FR64"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen></iframe>
                        </div>

                        <div class="card-body text-center d-block">
                            <b-form @submit.prevent="goToTest()">
                                <b-button size="lg" type="submit" variant="success" class="w-lg" >ทำข้อสอบ</b-button>
                            </b-form>
                        </div>
                    </div>
                </div>
            </div>
    </Horizontal>
</div></template>
